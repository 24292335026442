<template lang="pug">
div
  .row
    .col-xxl-8
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label {{ $t('Project_description.post_detail') }} (Thai)
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(description="" :label="$t('Project_description.title')" label-for="input-1")
            b-form-input#input-1(v-model="post.title_th" trim="" placeholder="untitle" :state="!(post.title_th == '')")
          b-form-group#fieldset-1(description="" :label="$t('Project_description.body')" label-for="input-1")
            ckeditor(:editor="editor" v-model="post.body_th" :config="editorConfig")
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label {{ $t('Project_description.post_detail') }} (English)
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(description="" :label="$t('Project_description.title')" label-for="input-1")
            b-form-input#input-1(v-model="post.title_en" trim="" placeholder="untitle" :state="!(post.title_en == '')")
          b-form-group#fieldset-1(description="" :label="$t('Project_description.body')" label-for="input-1")
            ckeditor(:editor="editor" v-model="post.body_en" :config="editorConfig")
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady && !!post.attachment")
        .card-header.pb-0
          .card-title
            h3.card-label {{ $t('Project_description.images') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-container.p-4
            .mb-5.pb-5(v-for="i in Math.ceil(post.attachment.length / 10)" :key="i")
              div(v-for="item in post.attachment.slice((i - 1) * 10, i * 10)" :key="item.id")
                b-img.mr-3.mb-3(v-if="item.filetype == 'image/jpeg' || item.filetype == 'image/png' || item.filetype == 'image/jpg'" thumbnail="" left="" v-bind="{\
                center: true,\
                width: 110,\
                }" :src="item.path" v-clipboard:copy="item.path" @click="copyImageURL('attach_' + item.id)" :id="'attach_' + item.id")
                b-img.mr-3.mb-3(v-bind="{\
                center: true,\
                width: 110,\
                }" v-else="" thumbnail="" left="" src="media/misc/file-icon.jpeg" v-clipboard:copy="item.path" @click="copyImageURL('attach_' + item.id)" :id="'attach_' + item.id")
                b-tooltip(:show="false" :ref="'attach_' + item.id" :target="'attach_' + item.id" variant="primary") Click to Copy url
      b-overlay(:show="isSaving" no-wrap="")  
    .col-xxl-4
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title 
            h3.card-label
              | {{ $t('Project_description.publish') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(label="สถานะ  " label-for="input-1")
            b-form-select(v-model="post.status" :options="status_list")
          b-button.mb-5(variant="primary" block="" @click="savePost" v-if="post.status == 0") Save
          b-button.mb-5(variant="success" block="" @click="savePost" v-else="") Publish
        b-overlay(:show="isSaving" no-wrap="")  
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title 
            h3.card-label {{ $t('Project_description.banner') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1.pt-3(description="" label="" label-for="input-1")
            #preview
              b-img.mb-3(v-if="hasImage" :src="imageSrc" fluid="" block="" rounded="")  
              b-img.mb-3(v-if="post.image_path && !hasImage" :src="post.image_path" fluid="" block="" rounded="")
            b-button.btn-sm.mb-5(v-if="hasImage" variant="danger" @click="clearImage" block="") Delete
            b-form-file(accept="image/jpeg, image/png" v-model="pictureFile" placeholder="กรุณาเลือกไฟล์" drop-placeholder="Drop file here...")
        b-overlay(:show="isSaving" no-wrap="")  
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title 
            h3.card-label
              | {{ $t('Project_description.parent_project') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(:label="$t('Project_description.parent_project')" label-for="input-1")
            b-form-select(v-model="post.parent_id" :options="parent_list")
        b-overlay(:show="isSaving" no-wrap="")  
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title 
            h3.card-label
              | {{ $t('Project_description.researcher') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(:label="$t('Project_description.researcher')" label-for="input-1")
            b-form-select(
              v-model="selectedResearcher"
              :options="researcher_list"
              value-field="id"
              text-field="name"
              @change="addResearcher"
            )
          b-table(small='' responsive='sm' :items='post.researcher' :fields="['name']" style='overflow: hidden')
            template(#cell()='data')
              | {{ data.item.name | str_limit(30) }}
              b-button.btn-sm.pt-0.pb-0.pr-2.pl-2.float-right(variant='danger' @click='deleteResearcher(data.index)') x
        b-overlay(:show="isSaving" no-wrap="")  
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title 
            h3.card-label
              | {{ $t('Project_description.attachment') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(description="" label="" label-for="input-1")
            b-form-file(accept="image/jpeg, image/png, application/pdf" v-model="attachFiles" placeholder="กรุณาเลือกไฟล์" drop-placeholder="Drop file here..." :file-name-formatter="formatNames" multiple="" :show="!hasFiles")
            b-button.btn-sm.mt-5(v-if="hasFiles" variant="danger" @click="clearAttachFiles" block="") Clear
            .clearfix.text-center.pt-3(v-if="hasFiles")
              span.center  OR 
              b-button.btn-sm.mt-3(variant="primary" @click="uploadAttachFiles" block="") Upload {{ attachFiles.length }} file(s)
          b-table(small="" responsive="sm" :items="post.attachment" :fields="['filename']" style="overflow: hidden")
            template(#cell()="data")
              | {{ data.value | str_limit(30) }}
              b-button.btn-sm.pt-0.pb-0.pr-2.pl-2.float-right(variant="danger" @click="deleteAttachment(data.item.id)") x
        b-overlay(:show="isSaving || isUploading" no-wrap="")  
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY, GET_PARENT, GET_RES, UPLOAD_FILE, UPLOAD_ATTACH, DELETE_ATTACH} from '../services.js';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

class InsertImage extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('insertImage', locale => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true,
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        const imageUrl = prompt('Image URL');

        editor.model.change(writer => {
          const imageElement = writer.createElement('imageBlock', {
            src: imageUrl,
          });

          // Insert the image in the current selection location.
          editor.model.insertContent(imageElement, editor.model.document.selection);
        });
      });

      return view;
    });
  }
}

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        title_th: '',
        body_th: '',
        title_en: '',
        body_en: '',
        parent_id: 0,
        image_path: '',
        image_attachment: [],
        status: 0,
      },
      pictureFile: null,
      attachFiles: [],
      imageSrc: null,
      parent_list: [{value: 0, text: 'Default'}],
      researcher_list: [],
      selectedResearcher: null,
      status_list: [
        {
          value: 0,
          text: 'Draft',
        },
        {
          value: 1,
          text: 'Published',
        },
      ],
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Autoformat,
          Bold,
          Italic,
          BlockQuote,
          Heading,
          Link,
          List,
          Paragraph,
          Alignment,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageResize,
          LinkImage,
          InsertImage,
          Table,
          TableToolbar,
        ],

        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'insertTable',
            'insertImage',
            'link',
            'bulletedList',
            'numberedList',
            'uploadImage',
            'blockQuote',
            'undo',
            'redo',
            'resizeImage',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],

          // Configuration of the TableProperties plugin.
          tableProperties: {
            // ...
          },

          // Configuration of the TableCellProperties plugin.
          tableCellProperties: {
            // ...
          },
        },
        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage:50',
            'resizeImage:75',
            'resizeImage:original',
          ],
        },
      },
    };
  },
  mounted() {
    //get category
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Project Description', route: '../'}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_PARENT)
        // go to which page after successfully login
        .then(res => {
          res.data.forEach(parent => {
            if (parent.id != this.post.id) {
              this.parent_list.push({
                value: parent.id,
                text: parent.title,
              });
            }
          });
        })
        .catch(error => {
          // console.log(error);
        });
      this.$store
        .dispatch(GET_RES)
        // go to which page after successfully login
        .then(res => {
          this.researcher_list.push({id: 0, name: 'No researcher'});
          res.data.forEach(researcher => {
            this.researcher_list.push(researcher);
          });
        })
        .catch(error => {
          // console.log(error);
        });
      if (this.$route.name == 'project_description.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then(res => {
            this.post = res.data[0];

            this.isReady = true;
          })
          .catch(error => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.cat_id <= 0 || this.post.title_th == '' || this.post.title_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, this.post)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            this.post = res.data[0];

            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(UPLOAD_FILE, formData)
                // go to which page after successfully login
                .then(res => {
                  if (res.status) {
                    this.post.image_path = res.data.image_path;
                  }
                  // console.log('Upload Banner');
                  this.isSaving = false;
                  this.pictureFile = null;
                  //Update image path
                  this.savePost('update_image');
                })
                .catch(error => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            //Upload Attachment
            this.uploadAttachFiles();

            // this.uploadFile();
            if (this.$route.name == 'project_description.add') {
              this.$router.push({
                name: 'project_description.edit',
                params: {id: this.post.id},
              });
            }
          }
          // console.log('Save post');
          this.isSaving = false;
          if (mode != 'update_image') {
            this.notify('success', 'Successfully!', 'Post Saved.');
            if (this.post.status == 1) {
              this.$router.push({
                name: 'project_description.list',
              });
            }
          }
        })
        .catch(error => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    uploadAttachFiles() {
      if (this.post.id) {
        //Upload Attachment
        if (this.attachFiles) {
          this.isUploading = true;
          const attachNum = this.attachFiles.length;
          let i = 0;
          this.attachFiles.forEach((item, index) => {
            let formData = new FormData();
            formData.append('file', item);
            // console.log(item.name);
            formData.append('id', this.post.id);
            formData.append('table', 'project_description');
            this.$store
              .dispatch(UPLOAD_ATTACH, formData)
              .then(res => {
                i++;
                if (res.status) {
                  this.notify('success', 'Successfully!', 'Upload [' + res.data.filename + '] successfully!');
                } else {
                  this.notify('success', 'Successfully!', 'Upload [' + item.name + '] successfully!');
                }
                if (i == attachNum) {
                  this.getData();
                }
              })
              .catch(err => {});
          });
          this.attachFiles = [];
          setTimeout(() => {
            this.isUploading = false;
          }, 1000);
        }
      } else {
        this.savePost();
      }
    },
    deleteAttachment(id) {
      this.isUploading = true;
      this.$store
        .dispatch(DELETE_ATTACH, {id: id})
        .then(res => {
          this.getData();
          this.isUploading = false;
        })
        .catch(err => {
          this.isUploading = false;
        });
    },
    copyImageURL(id) {},
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    clearImage() {
      this.pictureFile = null;
    },
    clearAttachFiles() {
      this.attachFiles = [];
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    deleteResearcher(index) {
      this.post.researcher.splice(index, 1);
    },
    addResearcher(id) {
      if (id === 0) return;
      // check if already in the list
      const isAdded = this.post.researcher.find(p => p.rsc_id === id || p.id === id);
      console.log(isAdded);
      if (isAdded) return;

      const result = this.researcher_list.find(p => p.id === id);
      this.post.researcher.push(result);
      this.selectedResearcher = null;
    },
  },
  computed: {
    hasImage() {
      return !!this.pictureFile;
    },
    hasFiles() {
      return !!this.attachFiles.length;
    },
  },
  watch: {
    pictureFile: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
